






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'ToolBar',
})
export default class ToolBar extends Vue {
  @Prop({ required: true, default: 'Home' })
  title!: string;

  @Prop({ required: false, default: false })
  showBackButton!: boolean;

  @Prop({ required: false, default: '' })
  backUrl!: string;

  state: boolean = false;

  openDrawer() {
    this.$store.dispatch('navigationDrawer', !this.state, { root: true });
  }
}
